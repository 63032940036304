import { StyledProgressBar } from "./style"

export const ProgerssBar = () => {
    return(
        <StyledProgressBar >
            <div>
                <span>VAGAS LIMITADAS</span>
            </div>
        </StyledProgressBar>
    )
}